import request from "@/utils/request";

interface ServiceItemCategory{
    "categoryname": string,
}

export function reload() {
    return {
        categoryname:'',
    }
}

const baseUrl="ServiceItemCategory/";
const serviceItemCategoryApi={
    //列表
    list(pageData: any,params:ServiceItemCategory){
        return request.post(`${baseUrl}GetServiceItemCategoryPage`,{
            "offset": pageData.current,
            "limit": pageData.size,
            "orderByFiled": "Id",
            "isAsc": true,
            queryOptions: {...params}
        })
    },
    //详情
    detail(id:string|number){
        return request.get(`${baseUrl}/GetServiceItemCategoryDetail?id=`+id)
    },
    //更改  0 新增 1 编辑
    updateOrAdd(type: number, data: any) {
        return request[type ?"put":"post"](baseUrl + (type ? 'UpdateServiceItemCategory' : 'CreateServiceItemCategory'), data)
    },
    //删除
    del(id:string|number){
        return request.del(`${baseUrl}RemoveServiceItemCategory?id=`+id)
    },
    noPageList(){
        return request.post(`${baseUrl}GetServiceItemCategoryList`,{})
    },

}
export default  serviceItemCategoryApi;